.competency-name {
    padding-left: 0px;
}

table > tbody > tr > td:nth-child(1) {
    font-weight: 500;
}

ul > li, ol > li {
    margin-left: 15px;
}